import React from 'react'

import { useFormikContext } from 'formik'

const Errors = () => {
    const { errors } = useFormikContext()
    console.log('ERRORS', errors)
    if (Object.keys(errors).length === 0) {
        return null
    }
    return (
        <>
            {Object.keys(errors).length ? (
                <div id='errors'>
                    <h4>Errors</h4>
                    <p>Please fix the following errors before submitting:</p>
                    <ul>
                        {Object.keys(errors).map((key, i) => {
                            return <li key={i}>{errors[key]}</li>
                        })}
                    </ul>
                </div>
            ) : null}
        </>
    )
}

export default Errors
