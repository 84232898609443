import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '../../components/TextField'
import RadioField from '../../components/RadioField'

const questions = [
    {
        name: 'financial_issues',
        title: 'Are you subject to any active or satisfied financial issues of insolvency?',
        text: "CCJ's, Individual Voluntary Arrangement/Trust Deed, Debt Relief Order. Bankruptcy/Sequestration",
    },
    {
        name: 'financial_criminal_convictions',
        title: 'Do you hold any unspent criminal convictions (including motoring offences)?',
        text: '',
    },
    {
        name: 'financial_court_case',
        title: 'Are you subject to pending or on-going criminal court cases?',
        text: '',
    },
    {
        name: 'financial_director',
        title: 'Are you currently a director at another company? Held previous directorships? Or been disqualified?',
        text: '',
    },
    {
        name: 'financial_lived_outside_uk',
        title: 'Have you worked or resided outside the UK in the last 5 years?',
        text: '',
    },
    {
        name: 'financial_redundant',
        title: 'Have you ever been made redundant, had your employment terminated by an Iron Mountain Group company or been dismissed for misconduct elsewhere in the last 5 years?',
        text: '',
    },
    {
        name: 'financial_government_clearance',
        title: 'Do you hold a valid Government Security Clearance?',
        text: '',
    },
    {
        name: 'financial_association_line_manager',
        title: 'Do you hold any known personal associations to your proposed Iron Mountain line manager?',
        text: '',
    },
]

import { useFormikContext, ErrorMessage } from 'formik'
const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>

const FinancialBackgroundChecks = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values, setFieldValue, handleBlur, setFieldTouched } =
        useFormikContext()

    const slimValues = Object.keys(values).reduce((acc, key) => {
        if (key.startsWith('financial_')) {
            acc[key] = values[key]
        }
        return acc
    }, {})

    const financialQuestionsContent = React.useMemo(() => {
        console.log('Render healthQuestionsContent')
        return questions.map(({ name, title, text }, i) => {
            return (
                <div key={i}>
                    <p>
                        <b>{title}</b>
                    </p>

                    {text ? <p>{text}</p> : null}

                    <RadioField name={name} />

                    {slimValues[name] === 'yes' ? (
                        <TextField
                            name={`${name}_details`}
                            multiline
                            minRows={3}
                        />
                    ) : null}
                </div>
            )
        })
    }, [slimValues])

    return (
        <Section title={t('title_financial_background_checks')}>
            {financialQuestionsContent}
            <TextField name='financial_other_details' multiline minRows={3} />
            <FormControlLabel
                control={
                    <Checkbox
                        name='financial_consent'
                        onChange={(e) => {
                            setFieldTouched('financial_consent', true)
                            setFieldValue('financial_consent', e.target.checked)
                        }}
                        onBlur={handleBlur}
                        checked={values.financial_consent}
                    />
                }
                label={
                    'I understand that any false statement of deliberate omission I have given in may be seen negatively, lead to immediate termination of assignment and prohibit me access to certain business environments.'
                }
            />
            <ErrorMessage name='financial_consent' component={Error} />
        </Section>
    )
}

export default FinancialBackgroundChecks
