/* global gtag */
import React from 'react'
import { useTranslation } from 'react-i18next'

const Success = () => {
    const { t } = useTranslation()

    React.useEffect(() => {
        gtag('event', 'form_success', {
            event_category: 'Form',
            value: window.location.search,
        })
    }, [])

    return (
        <>
            <h1>{t('success_title')}</h1>
            <p>{t('success_message')}</p>
        </>
    )
}

export default Success
