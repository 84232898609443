import React from 'react'

import { useTranslation } from 'react-i18next'

import TextField from '../../components/TextField'
import Section from '../../components/Section'
import MaskTextField from '../../components/MaskTextField'

const BankInformation = () => {
    const { t } = useTranslation()

    return (
        <Section title={t('title_bank_information')}>
            <TextField name='bank_name' required />
            <TextField name='bank_branch' />
            <TextField name='bank_account_name' required />
            <TextField name='bank_reference_number' />
            <MaskTextField name='bank_sort_code' required mask='##-##-##' />
            <MaskTextField
                name='bank_account_number'
                required
                mask='## ## ## ##'
            />
        </Section>
    )
}

export default BankInformation
