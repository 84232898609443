const generateOptions = (item) => ({
    value: item.toLowerCase().replace(/ /g, '_'),
    label: item,
})

// cSpell:disable
const nationalities = [
    'British',
    'Afghan',
    'Albanian',
    'Algerian',
    'American',
    'Andorran',
    'Angolan',
    'Anguillan',
    'Argentine',
    'Armenian',
    'Australian',
    'Austrian',
    'Azerbaijani',
    'Bahamian',
    'Bahraini',
    'Bangladeshi',
    'Barbadian',
    'Belarusian',
    'Belgian',
    'Belizean',
    'Beninese',
    'Bermudian',
    'Bhutanese',
    'Bolivian',
    'Botswanan',
    'Brazilian',
    'British Virgin Islander',
    'Bruneian',
    'Bulgarian',
    'Burkinan',
    'Burmese',
    'Burundian',
    'Cambodian',
    'Cameroonian',
    'Canadian',
    'Cape Verdean',
    'Cayman Islander',
    'Central African',
    'Chadian',
    'Chilean',
    'Chinese',
    'Citizen of Antigua and Barbuda',
    'Citizen of Bosnia and Herzegovina',
    'Citizen of Guinea-Bissau',
    'Citizen of Kiribati',
    'Citizen of Seychelles',
    'Citizen of the Dominican Republic',
    'Citizen of Vanuatu ',
    'Colombian',
    'Comoran',
    'Congolese (Congo)',
    'Congolese (DRC)',
    'Cook Islander',
    'Costa Rican',
    'Croatian',
    'Cuban',
    'Cymraes',
    'Cymro',
    'Cypriot',
    'Czech',
    'Danish',
    'Djiboutian',
    'Dominican',
    'Dutch',
    'East Timorese',
    'Ecuadorean',
    'Egyptian',
    'Emirati',
    'Equatorial Guinean',
    'Eritrean',
    'Estonian',
    'Ethiopian',
    'Faroese',
    'Fijian',
    'Filipino',
    'Finnish',
    'French',
    'Gabonese',
    'Gambian',
    'Georgian',
    'German',
    'Ghanaian',
    'Gibraltarian',
    'Greek',
    'Greenlandic',
    'Grenadian',
    'Guamanian',
    'Guatemalan',
    'Guinean',
    'Guyanese',
    'Haitian',
    'Honduran',
    'Hong Konger',
    'Hungarian',
    'Icelandic',
    'Indian',
    'Indonesian',
    'Iranian',
    'Iraqi',
    'Irish',
    'Israeli',
    'Italian',
    'Ivorian',
    'Jamaican',
    'Japanese',
    'Jordanian',
    'Kazakh',
    'Kenyan',
    'Kittitian',
    'Kosovan',
    'Kuwaiti',
    'Kyrgyz',
    'Lao',
    'Latvian',
    'Lebanese',
    'Liberian',
    'Libyan',
    'Liechtenstein citizen',
    'Lithuanian',
    'Luxembourger',
    'Macanese',
    'Macedonian',
    'Malagasy',
    'Malawian',
    'Malaysian',
    'Maldivian',
    'Malian',
    'Maltese',
    'Marshallese',
    'Martiniquais',
    'Mauritanian',
    'Mauritian',
    'Mexican',
    'Micronesian',
    'Moldovan',
    'Monegasque',
    'Mongolian',
    'Montenegrin',
    'Montserratian',
    'Moroccan',
    'Mosotho',
    'Mozambican',
    'Namibian',
    'Nauruan',
    'Nepalese',
    'New Zealander',
    'Nicaraguan',
    'Nigerian',
    'Nigerien',
    'Niuean',
    'North Korean',
    'Northern Irish',
    'Norwegian',
    'Omani',
    'Pakistani',
    'Palauan',
    'Palestinian',
    'Panamanian',
    'Papua New Guinean',
    'Paraguayan',
    'Peruvian',
    'Pitcairn Islander',
    'Polish',
    'Portuguese',
    'Prydeinig',
    'Puerto Rican',
    'Qatari',
    'Romanian',
    'Russian',
    'Rwandan',
    'Salvadorean',
    'Sammarinese',
    'Samoan',
    'Sao Tomean',
    'Saudi Arabian',
    'Senegalese',
    'Serbian',
    'Sierra Leonean',
    'Singaporean',
    'Slovak',
    'Slovenian',
    'Solomon Islander',
    'Somali',
    'South African',
    'South Korean',
    'South Sudanese',
    'Spanish',
    'Sri Lankan',
    'St Helenian',
    'St Lucian',
    'Stateless',
    'Sudanese',
    'Surinamese',
    'Swazi',
    'Swedish',
    'Swiss',
    'Syrian',
    'Taiwanese',
    'Tajik',
    'Tanzanian',
    'Thai',
    'Togolese',
    'Tongan',
    'Trinidadian',
    'Tristanian',
    'Tunisian',
    'Turkish',
    'Turkmen',
    'Turks and Caicos Islander',
    'Tuvaluan',
    'Ugandan',
    'Ukrainian',
    'Uruguayan',
    'Uzbek',
    'Vatican citizen',
    'Venezuelan',
    'Vietnamese',
    'Vincentian',
    'Wallisian',
    'Yemeni',
    'Zambian',
    'Zimbabwean',
]
// cSpell:enable

const config = {
    apiBase: window.location.href.includes('localhost')
        ? 'http://127.0.0.1:4000'
        : '',
    initialValues: {
        title: '',
        surname: '',
        forename: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_county: '',
        address_postcode: '',
        gender: '',
        marital_status: '',
        date_of_birth: null,
        ni_number: '',
        nationality: '',
        home_tel_number: '',
        mobile_tel_number: '',
        email_address: '',
        position: '',
        site: '',
        notice_period: '',
        transport: '',
        license: '',
        bank_name: '',
        bank_branch: '',
        bank_account_name: '',
        bank_reference_number: '',
        bank_sort_code: '',
        bank_account_number: '',
        skills: [],
        cvFile: [],
        identityFile: [],
        addressFile: [],
        niFile: [],
        cvFileNames: [],
        identityFileNames: [],
        addressFileNames: [],
        niFileNames: [],
        terms: false,
        gdpr_consent: false,
        date_last_medical: null,
        health_details: '',
        health_allergies: 'no',
        health_bones: 'no',
        health_disability: 'no',
        health_drugs: 'no',
        health_ear: 'no',
        health_eye: 'no',
        health_headaches: 'no',
        health_heart: 'no',
        health_lungs: 'no',
        health_medical_treatment: 'no',
        health_medicine: 'no',
        health_nervous_system: 'no',
        health_other: 'no',
        health_skin: 'no',
        health_specialist: 'no',
        health_work: 'no',
        health_allergies_details: '',
        health_bones_details: '',
        health_disability_details: '',
        health_drugs_details: '',
        health_ear_details: '',
        health_eye_details: '',
        health_headaches_details: '',
        health_heart_details: '',
        health_lungs_details: '',
        health_medical_treatment_details: '',
        health_medicine_details: '',
        health_nervous_system_details: '',
        health_other_details: '',
        health_skin_details: '',
        health_specialist_details: '',
        health_work_details: '',
        employee_statement: '',
        student_loan: '',
        student_loan_before_april: '',
        student_loan_repay_direct: '',
        student_loan_type: '',
        postgraduate_loan: '',
        postgraduate_loan_repay_direct: '',
        criminal_record_declaration: '',
        criminal_record_declaration_details: '',
        background_check_consent: false,
        mothers_maiden_name: '',
        background_check_address_1_from: null,
        background_check_address_1_to: null,
        background_check_address_1: '',
        background_check_address_2_from: null,
        background_check_address_2_to: null,
        background_check_address_2: '',
        background_check_address_3_from: null,
        background_check_address_3_to: null,
        background_check_address_3: '',
        background_check_address_4_from: null,
        background_check_address_4_to: null,
        background_check_address_4: '',
        background_check_address_5_from: null,
        background_check_address_5_to: null,
        background_check_address_5: '',

        drug_alcohol: false,

    },
    // cSpell:disable
    demoValues: {
        one: {
            title: 'MR',
            surname: 'Poore',
            forename: 'Phil',
            address_line_1: '10',
            address_line_2: 'Henhurst Farm',
            address_city: 'Burton on Trent',
            address_county: 'Staffordshire',
            address_postcode: 'DE13 9TE',
            gender: 'male',
            marital_status: 'single',
            date_of_birth: '1987-06-27T23:00:00.000Z',
            ni_number: 'JG234234A',
            nationality: 'british',
            home_tel_number: '0123456789',
            mobile_tel_number: '07789818457',
            email_address: 'phil.j.poore@gmail.com',
            position: 'Example Job Name',
            site: 'Example Site Name',
            notice_period: '1_month',
            transport: 'public_transport',
            license: 'full',
            bank_name: 'HSBC',
            bank_branch: 'Burton',
            bank_account_name: 'Phil Poore',
            bank_reference_number: '',
            bank_sort_code: '112233',
            bank_account_number: '11223344',
            skills: [
                'admin/clerical',
                'production_operator',
                'flt',
                'counterbalance',
                'warehousing',
            ],
            cvFile: null,
            identityFile: null,
            addressFile: null,
            niFile: null,
            terms: false,
            gdpr_consent: false,
            date_last_medical: null,
            health_details: '',
            health_allergies: 'no',
            health_bones: 'no',
            health_disability: 'no',
            health_drugs: 'no',
            health_ear: 'no',
            health_eye: 'no',
            health_headaches: 'no',
            health_heart: 'no',
            health_lungs: 'no',
            health_medical_treatment: 'no',
            health_medicine: 'no',
            health_nervous_system: 'no',
            health_other: 'no',
            health_skin: 'no',
            health_specialist: 'no',
            health_work: 'no',
            health_allergies_details: '',
            health_bones_details: '',
            health_disability_details: '',
            health_drugs_details: '',
            health_ear_details: '',
            health_eye_details: '',
            health_headaches_details: '',
            health_heart_details: '',
            health_lungs_details: '',
            health_medical_treatment_details: '',
            health_medicine_details: '',
            health_nervous_system_details: '',
            health_other_details: '',
            health_skin_details: '',
            health_specialist_details: '',
            health_work_details: '',
            employee_statement: '',
            student_loan: 'no',
            student_loan_before_april: '',
            student_loan_repay_direct: '',
            student_loan_type: '',
            postgraduate_loan: 'no',
            postgraduate_loan_repay_direct: '',
        },
    },
    // cSpell:enable
    titles: [
        {
            value: 'MR',
            label: 'Mr.',
        },
        {
            value: 'MRS',
            label: 'Mrs.',
        },
        {
            value: 'MISS',
            label: 'Miss.',
        },
    ],
    maritalStatuses: [
        {
            value: 'married',
            label: 'Married',
        },
        {
            value: 'single',
            label: 'Single',
        },
        {
            value: 'widowed',
            label: 'Widowed',
        },
        {
            value: 'divorced',
            label: 'Divorced',
        },
    ],
    genders: [
        {
            value: 'male',
            label: 'Male',
        },
        {
            value: 'female',
            label: 'Female',
        },
        {
            value: 'prefer-not-to-say',
            label: 'Prefer not to say',
        },
    ],
    nationalities: nationalities.map(generateOptions),
    noticePeriods: [
        'Available Immediately',
        '1 Week',
        '2 Weeks',
        '3 Weeks',
        '1 Month',
        '2 Months',
        '3 Months',
    ].map(generateOptions),
    transports: ['Public Transport', 'Own Car'].map(generateOptions),
    licenses: ['Full', 'Provisional'].map(generateOptions),
    skills: [
        'Admin/Clerical',
        'Computer Literate',
        'JIT Manufacturing',
        'Production Operator',
        'FLT',
        'Counterbalance',
        'Reach',
        'VNA',
        'Warehousing',
        'Refuse Collection',
        'Picking & Packing',
        'Trades',
    ].map(generateOptions),
    healthQuestions: [
        {
            name: 'health_lungs',
            title: 'Conditions of the lungs?',
            text: 'Asthmas? Bronchitis? Pleurisy? Tuberculosis? Other chest complaints? Coughing up blood? Shortness of breath? Any other conditions?',
        },
        {
            name: 'health_heart',
            title: 'Conditions of the heart?',
            text: 'High blood pressure?  Heart attacks? Angina?',
        },
        {
            name: 'health_nervous_system',
            title: 'Nervous system disorder?',
            text: 'Blackouts? Epilepsy? Muscular weakness? Paralysis?',
        },
        {
            name: 'health_headaches',
            title: 'Migraine or persistent headaches?',
        },
        {
            name: 'health_bones',
            title: 'Conditions of the bones, joints and limbs?',
            text: 'Arthritis? Rheumatism? Back problems? Neck or shoulder problems? Sciatica? Upper limb disorder? Tennis elbow? Any other conditions?',
        },
        {
            name: 'health_allergies',
            title: 'Allergies?',
            text: 'Including allergies to drugs, animals and pollens',
        },
        {
            name: 'health_skin',
            title: 'Skin conditions?',
            text: 'Eczema? Dermatitis? Psoriasis? Recent Infection? Skin cancer?',
        },
        {
            name: 'health_eye',
            title: 'Eye conditions?',
            text: 'Restricted vision? Glaucoma? Iritis? Any other condition?',
        },
        {
            name: 'health_ear',
            title: 'Ear conditions?',
            text: 'Restricted hearing? Tinnitus? Ear infections?',
        },
        {
            name: 'health_drugs',
            title: 'Alcohol or drug problems?',
            text: 'Problems related to alcohol or drug usage or dependency?',
        },
        {
            name: 'health_specialist',
            title: 'Have you consulted a specialist or needed any operations other than already stated?',
        },
        {
            name: 'health_medical_treatment',
            title: 'Are you receiving medical treatment at the present time?',
        },
        {
            name: 'health_medicine',
            title: 'Do you take any regular medication?',
        },
        {
            name: 'health_disability',
            title: 'Are you aware of having any disability that is covered by the Disability Discrimination Act?',
        },
        {
            name: 'health_work',
            title: 'Have you any disabilities affecting sight, hearing, standing, sitting, walking, lifting, driving, stair climbing, use of the hands or ability to carry out any work indicated in section 1?',
        },
        {
            name: 'health_other',
            title: 'Have you any other health issues that have not been mentioned above or about which you would like to provide further details?',
        },
    ],
}

export default config
