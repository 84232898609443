import React from 'react'

import { useTranslation } from 'react-i18next'

import TextField from '../../components/TextField'
import Select from '../../components/Select'
import DatePicker from '../../components/DatePicker'
import Section from '../../components/Section'

import config from '../../config/config'
import MaskTextField from '../../components/MaskTextField'

const { titles, genders, maritalStatuses, nationalities } = config

const PersonalDetails = () => {
    const { t } = useTranslation()

    return (
        <Section title={t('title_personal_details')}>
            <Select name='title' items={titles} required />
            <TextField name='forename' required />
            <TextField name='surname' required />
            <TextField name='address_line_1' required />
            <TextField name='address_line_2' />
            <TextField name='address_city' required />
            <TextField name='address_county' required />

            <TextField name='address_postcode' required />

            <Select name='gender' items={genders} required />

            <Select name='marital_status' items={maritalStatuses} required />

            <DatePicker name='date_of_birth' required />

            <MaskTextField name='ni_number' required mask='AA ## ## ## A' />

            <Select name='nationality' items={nationalities} required />

            <MaskTextField
                name='mobile_tel_number'
                required
                mask='##### ######'
            />
            <MaskTextField name='home_tel_number' mask='##### ######' />

            <TextField name='email_address' required />
        </Section>
    )
}

export default PersonalDetails
