const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#343434',
        },
        secondary: {
            main: '#82017f',
        },
    },
    spacing: 12,
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                margin: 'normal',
                fullWidth: true,
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'normal',
                fullWidth: true,
            },
        },
    },
}

export default themeOptions
