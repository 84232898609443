import * as React from 'react'
import { IMaskInput } from 'react-imask'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { useField } from 'formik'

import { useTranslation } from 'react-i18next'

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props
    return (
        <IMaskInput
            {...other}
            mask={mask}
            definitions={{
                '#': /[0-9]/,
                'A': /[a-zA-Z]/,
            }}
            inputRef={ref}
            onAccept={(value) =>
                onChange({ target: { name: props.name, value } })
            }
            overwrite
        />
    )
})

const MaskTextField = ({ name, required, ...props }) => {
    const [field, meta] = useField(name)
    const { t } = useTranslation()
    const label = t('label_' + name)
    return (
        <FormControl error={!!(meta.touched && meta.error)}>
            <InputLabel htmlFor={'id_' + name} required={required}>
                {label}
            </InputLabel>
            <OutlinedInput
                id={'id_' + name}
                value={meta.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={name}
                label={label}
                inputComponent={TextMaskCustom}
                inputProps={props}
                required={required}
            />
            {meta.touched && meta.error ? (
                <FormHelperText id='component-error-text'>
                    {meta.error}
                </FormHelperText>
            ) : null}
        </FormControl>
    )
}

export default MaskTextField
