import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

const Security = ({ jobData = {} }) => {
    const { t } = useTranslation()

    return (
        <Section title={t('title_security')}>
            <p>
                For security reasons any temporary worker assigned to any client
                through Triangle Recruitment Limited should note the following:
            </p>
            <ul>
                <li>You and your bags may be searched at any time.</li>
                <li>
                    Any criminal offence - no matter how small - committed on
                    the clients premises will result in instant dismissal and
                    may be reported to the police.
                </li>
                <li>
                    If I.D. Badges are required they must be worn at all times
                    whilst on the client premises.
                </li>
            </ul>
        </Section>
    )
}

export default Security
