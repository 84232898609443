import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '../../components/TextField'
import DatePicker from '../../components/DatePicker'

import { useFormikContext, ErrorMessage } from 'formik'
const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>

const BackgroundChecks = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values, setFieldValue, handleBlur, setFieldTouched } =
        useFormikContext()
    return (
        <Section title={t('title_background_checks')}>
            <p>
                As a condition of your Terms of Engagement with Triangle
                Recruitment Ltd, engaged to work at Iron Mountain you are
                required to sign a declaration authorising Triangle Recruitment
                to carry out background checks. These checks will include
                references from previous Employers / Educational Establishments
                as well as previous addresses, credit history checks, terrorism
                checks and Criminal Convictions (which are not a spent
                conviction within the meaning of the Rehabilitation of Offenders
                Act 1974) and where necessary immigration checks.
            </p>
            <p>
                In addition by signing below you are declaring that to the best
                of your knowledge you have no County Court Judgements or
                Criminal convictions and that you authorise the relevant checks
                to be carried out on your behalf.
            </p>
            <TextField name='mothers_maiden_name' required />
            <p>
                <b>
                    Please provide the last FIVE years home addresses below to
                    enable these checks:
                </b>
            </p>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <td>Date From</td>
                        <td>Date To</td>
                        <td>Address</td>
                    </tr>
                </thead>
                <tbody style={{ verticalAlign: 'top' }}>
                    {[1, 2, 3, 4, 5].map((a, i) => {
                        return (
                            <tr key={a}>
                                <td style={{ width: 150 }}>
                                    <DatePicker
                                        name={`background_check_address_${a}_from`}
                                    />
                                </td>
                                <td style={{ width: 150 }}>
                                    <DatePicker
                                        name={`background_check_address_${a}_to`}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        name={`background_check_address_${a}`}
                                        multiline
                                        minRows={3}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <p>
                In accordance with the Data Protection Act 1998 I give my
                permission for the above checks to be carried out and Triangle
                recruitment Ltd to act on my behalf including the disclosure of
                any information to relevant third parties (i.e. Iron Mountain
                and their Customers)
            </p>
            <FormControlLabel
                control={
                    <Checkbox
                        name='background_check_consent'
                        onChange={(e) => {
                            setFieldTouched('background_check_consent', true)
                            setFieldValue(
                                'background_check_consent',
                                e.target.checked,
                            )
                        }}
                        onBlur={handleBlur}
                        checked={values.background_check_consent}
                    />
                }
                label={'I agree to the Background Checks'}
            />
            <ErrorMessage name='background_check_consent' component={Error} />
        </Section>
    )
}

export default BackgroundChecks
