import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import RadioField from '../../components/RadioField'
import TextField from '../../components/TextField'
import { useFormikContext } from 'formik'
import { ErrorMessage } from 'formik'

const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>

const CriminalRecordDeclaration = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values } = useFormikContext()

    return (
        <Section title={t('title_declaration')}>
            <p>
                I understand and accept the conditions of employment and
                undertake to abide by the rules outlined.
            </p>
            <p>
                This declaration is in addition to any a given client may
                require you to sign.
            </p>
            <p>
                Have you ever been convicted of a Criminal Offence other than a
                minor road traffic office (which is not a spent conviction
                within meaning of the Rehabilitation of Offenders Act 1974)?
            </p>
            <RadioField name='criminal_record_declaration' />
            {values.criminal_record_declaration === 'yes' ? (
                <>
                    <p>Please provide details:</p>
                    <TextField
                        name='criminal_record_declaration_details'
                        minRows={3}
                        multiline
                    />
                </>
            ) : null}

            <ErrorMessage
                name='criminal_record_declaration'
                component={Error}
            />
            <p>
                Making a false declaration on the above question is an offence
                of obtaining a pecuniary advantage under the Theft Act 1968.
                This offence carries conviction, substantial fines and/or
                imprisonment.
            </p>
        </Section>
    )
}

export default CriminalRecordDeclaration
