import React from 'react'
import MenuItem from '@mui/material/MenuItem'

import TextField from './TextField'

const SelectOptions = ({ value, label }) => (
    <MenuItem key={value} value={value}>
        {label}
    </MenuItem>
)

const Select = ({ items = [], ...props }) => {
    const itemContent = React.useMemo(() => {
        return items.map(SelectOptions)
    }, [items])

    return (
        <TextField select {...props}>
            {itemContent}
        </TextField>
    )
}

export default Select
