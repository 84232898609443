import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useFormikContext } from 'formik'
import RadioField from '../../components/RadioField'

const HMRC = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext()
    return (
        <Section title={t('title_hmrc')}>
            <h3>Employee Statement</h3>
            <p>You need to select one of the following statements A, B or C:</p>
            <RadioGroup
                name={'employee_statement'}
                value={values.employee_statement}
                onChange={(e, value) =>
                    setFieldValue('employee_statement', value)
                }>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <FormControlLabel
                                    value='A'
                                    control={<Radio />}
                                    label='A'
                                />
                            </td>
                            <td>
                                This is my first job since 6 April and I’ve not
                                been receiving taxable Jobseeker’s Allowance,
                                Employment and Support Allowance, taxable
                                Incapacity Benefit, State or Occupational
                                Pension.
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <FormControlLabel
                                    value='B'
                                    control={<Radio />}
                                    label='B'
                                />
                            </td>
                            <td>
                                This is now my only job but since 6 April I’ve
                                had another job, or received taxable Jobseeker’s
                                Allowance, Employment and Support Allowance or
                                taxable Incapacity Benefit. I do not receive a
                                State or Occupational Pension.
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <FormControlLabel
                                    value='C'
                                    control={<Radio />}
                                    label='C'
                                />
                            </td>
                            <td>
                                I plan to keep my other job, this will be a
                                second additional job or I receive a State or
                                Occupational Pension
                            </td>
                        </tr>
                    </tbody>
                </table>
            </RadioGroup>

            <h3>Student Loan</h3>
            <p>
                For more guidance about repaying go to
                www.gov.uk/repaying-your-student-loan
            </p>

            <p>
                Do you have one of the Student Loan Plans described below which
                is not fully repaid?
            </p>
            <RadioField name='student_loan' />

            {values.student_loan === 'yes' ? (
                <>
                    <p>
                        Did you complete or leave your studies before 6th April?
                    </p>
                    <RadioField name='student_loan_before_april' />
                </>
            ) : null}

            {values.student_loan_before_april === 'yes' ? (
                <>
                    <p>
                        Are you repaying your Student Loan directly to the
                        Student Loans Company by direct debit?
                    </p>

                    <RadioField name='student_loan_repay_direct' />
                </>
            ) : null}

            {values.student_loan_repay_direct === 'no' ? (
                <>
                    <p>What type of Student Loan do you have?</p>
                    <RadioField
                        name='student_loan_type'
                        items={{
                            plan1: 'Plan 1',
                            plan2: 'Plan 2',
                            both: 'Both',
                        }}
                    />
                </>
            ) : null}

            <h4>Student Loan Plans</h4>
            <p>
                You’ll have a <b>Plan 1 Student Loan</b> if:
            </p>
            <ul>
                <li>
                    you lived in Scotland or Northern Ireland when you started
                    your course (undergraduate or postgraduate)
                </li>
                <li>
                    you lived in England or Wales and started your undergraduate
                    course before 1 September 2012
                </li>
            </ul>

            <p>
                You’ll have a <b>Plan 2 Student Loan</b> if:
            </p>
            <ul>
                <li>
                    you lived in England or Wales and started your undergraduate
                    course on or after 1 September 2012
                </li>
                <li>your loan is a Part Time Maintenance Loan</li>
                <li>your loan is an Advanced Learner Loan</li>
                <li>your loan is a Postgraduate Healthcare Loan</li>
            </ul>

            <h3>Postgraduate Loan</h3>
            <p>
                For more guidance about funding and repaying go to
                www.gov.uk/funding-for-postgraduate-study
            </p>

            <p>Do you have a Postgraduate Loan which is not fully repaid?</p>
            <RadioField name='postgraduate_loan' />

            <p>You’ll have a Postgraduate Loan if:</p>
            <ul>
                <li>
                    you lived in England and started your Postgraduate Master’s
                    course on or after 1 August 2016
                </li>
                <li>
                    you lived in Wales and started your Postgraduate Master’s
                    course on or after 1 August 2017
                </li>
                <li>
                    you lived in England or Wales and started your Postgraduate
                    Doctoral course on or after 1 August 2018
                </li>
            </ul>

            {values.postgraduate_loan === 'yes' ? (
                <>
                    <p>
                        Are you repaying your Postgraduate Loan direct to the
                        Student Loans Company by direct debit?
                    </p>
                    <RadioField name='postgraduate_loan_repay_direct' />
                </>
            ) : null}
        </Section>
    )
}

export default HMRC
