import * as yup from 'yup'

const sectionSchemas = {
    personalDetails: {
        title: yup.string().label('Title').required(),
        surname: yup.string().label('Surname').required(),
        forename: yup.string().label('Forename').required(),
        address_line_1: yup.string().label('Address Line 1').required(),
        address_line_2: '',
        address_city: yup.string().label('Address City').required(),
        address_county: yup.string().label('Address County').required(),
        address_postcode: yup.string().label('Post Code').required(),
        gender: yup.string().label('Gender').required(),
        marital_status: yup.string().label('Marital Status').required(),
        date_of_birth: yup
            .string()
            .label('Date of Birth')
            .required()
            .nullable(),
        ni_number: yup.string().label('National Insurance').required(),
        nationality: yup.string().label('Nationality').required(),
        home_tel_number: yup.string().label('Home Telephone Number'),
        mobile_tel_number: yup
            .string()
            .label('Mobile Telephone Number')
            .required(),
        email_address: yup
            .string()
            .label('Email Address')
            .required()
            .email()
            .max(50),
    },

    generalInformation: {
        position: yup.string().label('Position'),
        site: yup.string().label('Site'),
        notice_period: yup.string().label('Notice Period').required(),
        transport: yup.string().label('Transport').required(),
        license: yup.string().label('License').required(),
    },

    bankInformation: {
        bank_name: yup.string().label('Bank Name').required(),
        bank_branch: yup.string().label('Bank Branch'),
        bank_account_name: yup.string().label('Bank Account Name').required(),
        bank_reference_number: yup.string().label('Bank Reference Number'),
        bank_sort_code: yup.string().label('Bank Sort Code').required(),
        bank_account_number: yup
            .string()
            .label('Bank Account Number')
            .required(),
    },

    documents: {
        cvFile: yup.array().label('CV').nullable().of(yup.string()),
        identityFile: yup
            .array()
            .required()
            .label('Proof of Identity')
            .nullable()
            .of(yup.string()),
        addressFile: yup
            .array()
            .label('Proof of Address')
            .nullable()
            .of(yup.string()),
        niFile: yup
            .array()
            .required()
            .label('Proof of National Insurance')
            .nullable()
            .of(yup.string()),
    },
    gdpr: {
        gdpr_consent: yup
            .boolean()
            .label('GDPR Consent')
            .default(false)
            .required('Must agree to GDPR Declaration')
            .oneOf([true], 'Must agree to GDPR Declaration'),
    },
    terms: {
        terms: yup
            .boolean()
            .label('Terms')
            .default(false)
            .required('Must agree to Terms of Engagement')
            .oneOf([true], 'Must agree to Terms of Engagement'),
    },
    health: {
        date_last_medical: yup.date().label('Date Last Medical').nullable(),
    },
    hmrc: {
        employee_statement: yup.string().label('Employee Statement').required(),
        student_loan: yup.string().label('Student Loan').required(),
        postgraduate_loan: yup.string().label('Postgraduate Loan').required(),
    },
    skills: {},
    criminalRecordDeclaration: {
        criminal_record_declaration: yup.string().label('Criminal Record Declaration').required(),
    },
    security: {},
    backgroundChecks: {
        mothers_maiden_name: yup.string().label('Mothers Maiden Name').required(),
        background_check_consent: yup
            .boolean()
            .label('Background Check Consent')
            .default(false)
            .required('Must agree to Background Checks')
            .oneOf([true], 'Must agree to Background Checks'),
    },
    drugAlcohol: {
        drug_alcohol: yup
            .boolean()
            .label('Compulsory Drug & Alcohol Declaration')
            .default(false)
            .required('Must agree to Compulsory Drug & Alcohol Declaration')
            .oneOf([true], 'Must agree to Compulsory Drug & Alcohol Declaration'),
    },
    deductionOfWages: {
        deduction_of_wages: yup
            .boolean()
            .label('Deduction of Wages')
            .default(false)
            .required('Must agree to Deduction of Wages')
            .oneOf([true], 'Must agree to Deduction of Wages'),
    },
    financialBackgroundChecks: {
        financial_issues: yup
            .string()
            .label('Financial Issues')
            .required('Financial Issues is required'),
        financial_criminal_convictions: yup
            .string()
            .label('Financial Criminal Convictions')
            .required('Financial Criminal Convictions is required'),
        financial_court_case: yup
            .string()
            .label('Financial Court Case')
            .required('Financial Court Case is required'),
        financial_director: yup
            .string()
            .label('Financial Director')
            .required('Financial Director is required'),
        financial_lived_outside_uk: yup
            .string()
            .label('Financial Lived Outside UK')
            .required('Financial Lived Outside UK is required'),
        financial_redundant: yup
            .string()
            .label('Financial Redundant')
            .required('Financial Redundant is required'),
        financial_government_clearance: yup
            .string()
            .label('Financial Government Clearance')
            .required('Financial Government Clearance is required'),
        financial_association_line_manager: yup
            .string()
            .label('Financial Association Line Manager')
            .required('Financial Association Line Manager is required'),
        financial_consent: yup
            .boolean()
            .label('Financial Consent')
            .default(false)
            .required('Must agree to Financial Consent')
            .oneOf([true], 'Must agree to Financial Consent')
    }
}

const sectionFields = {
    personalDetails: [
        'title',
        'surname',
        'forename',
        'address_line_1',
        'address_line_2',
        'address_city',
        'address_county',
        'address_postcode',
        'gender',
        'marital_status',
        'date_of_birth',
        'ni_number',
        'nationality',
        'home_tel_number',
        'mobile_tel_number',
        'email_address',
    ],
    generalInformation: [
        'position',
        'site',
        'notice_period',
        'transport',
        'license',
    ],
    skills: ['skills'],
    bankInformation: [
        'bank_name',
        'bank_branch',
        'bank_account_name',
        'bank_reference_number',
        'bank_sort_code',
        'bank_account_number',
    ],
    documents: [
        'cvFile',
        'identityFile',
        'addressFile',
        'niFile',
        'cvFileNames',
        'identityFileNames',
        'addressFileNames',
        'niFileNames',
    ],
    health: [
        'date_last_medical',
        'health_details',
        'health_allergies',
        'health_bones',
        'health_disability',
        'health_drugs',
        'health_ear',
        'health_eye',
        'health_headaches',
        'health_heart',
        'health_lungs',
        'health_medical_treatment',
        'health_medicine',
        'health_nervous_system',
        'health_other',
        'health_skin',
        'health_specialist',
        'health_work',
        'health_allergies_details',
        'health_bones_details',
        'health_disability_details',
        'health_drugs_details',
        'health_ear_details',
        'health_eye_details',
        'health_headaches_details',
        'health_heart_details',
        'health_lungs_details',
        'health_medical_treatment_details',
        'health_medicine_details',
        'health_nervous_system_details',
        'health_other_details',
        'health_skin_details',
        'health_specialist_details',
        'health_work_details',
    ],
    hmrc: [
        'employee_statement',
        'student_loan',
        'student_loan_before_april',
        'student_loan_repay_direct',
        'student_loan_type',
        'postgraduate_loan',
        'postgraduate_loan_repay_direct',
    ],
    gdpr: ['gdpr_consent'],
    terms: ['terms'],
    criminalRecordDeclaration: ['criminal_record_declaration', 'criminal_record_declaration_details'],
    security: [],
    backgroundChecks: [
        'mothers_maiden_name',
        'background_check_consent',
        'background_check_address_1_from',
        'background_check_address_1_to',
        'background_check_address_1',
        'background_check_address_2_from',
        'background_check_address_2_to',
        'background_check_address_2',
        'background_check_address_3_from',
        'background_check_address_3_to',
        'background_check_address_3',
        'background_check_address_4_from',
        'background_check_address_4_to',
        'background_check_address_4',
        'background_check_address_5_from',
        'background_check_address_5_to',
        'background_check_address_5',
    ],
    drugAlcohol: [
        'drug_alcohol',
    ],
    deductionOfWages: [],
    financialBackgroundChecks: [
        'financial_issues',
        'financial_criminal_convictions',
        'financial_court_case',
        'financial_director',
        'financial_lived_outside_uk',
        'financial_redundant',
        'financial_government_clearance',
        'financial_association_line_manager',
    ],
}

export { sectionSchemas, sectionFields }
