import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { useFormikContext, ErrorMessage } from 'formik'
const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>

const GDPR = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values, setFieldValue, handleBlur, setFieldTouched } =
        useFormikContext()
    return (
        <Section title={t('title_gdpr')}>
            <p>
                Triangle Recruitment Limited processes personal data and
                sensitive data. We process this data in accordance with data
                protection legislation (which includes General Data Protection
                Regulation and Data Protection Act 2018) and Triangle
                Recruitment Limited&lsquo;s privacy information.{' '}
                <b>We do not sell your data to third parties.</b>
            </p>
            <h3>GDPR Declaration</h3>
            <p>
                I, {values.forename ? values.forename : '[FORENAME]'}{' '}
                {values.surname ? values.surname : '[SURNAME]'} hereby give my
                consent to the Company to process the following information:
            </p>
            <h4>Personal data</h4>
            <ul>
                <li>Name</li>
                <li>Date of birth</li>
                <li>
                    Contact details, including telephone number, email address
                    and postal address{' '}
                </li>
                <li>Experience, training and qualifications</li>
                <li>CV</li>
                <li>National insurance number</li>
                <li>Include any other relevant personal data</li>
                <li>Right to Work Proof</li>
            </ul>
            <h4>Sensitive personal data</h4>
            <ul>
                <li>
                    Disability, Medical Health conditions relevant to the role
                </li>
                <li>
                    Emergency Medical Contact details limited to Name and
                    contact details of that person/s.
                </li>
                <li>Criminal Convictions </li>
            </ul>

            <p>
                I consent to the Company processing the above personal data for
                the following purposes:
            </p>
            <ul>
                <li>
                    For the Company to provide me with work-finding services.
                </li>
                <li>
                    For the Company to process with or transfer my personal data
                    to their named client/s in order to provide me with
                    work-finding services.
                </li>
                <li>
                    For the Company to process my data on a computerised
                    database provided by Tempaid 5, Oxford Software in order to
                    provide me with work-finding and payroll services.
                </li>
                <li>
                    Any other relevant purposes for processing personal data
                </li>
            </ul>
            <p>
                I also consent to the Company processing my personal data with
                third parties including [The REC] for the purposes of internal
                audits and investigations carried out on the Company to ensure
                that the Company is complying with all relevant laws and
                obligations.
            </p>
            <p>The consent I give to the Company will last for 3 years.</p>
            <p>
                I am aware that I have the right to withdraw my consent at any
                time by informing the Company that I wish to do so.
            </p>
            <FormControlLabel
                control={
                    <Checkbox
                        name='gdpr_consent'
                        onChange={(e) => {
                            setFieldTouched('gdpr_consent', true)
                            setFieldValue('gdpr_consent', e.target.checked)
                        }}
                        onBlur={handleBlur}
                        checked={values.gdpr_consent}
                    />
                }
                label={'I agree to the GDPR Declaration'}
            />
            <ErrorMessage name='gdpr_consent' component={Error} />
        </Section>
    )
}

export default GDPR
