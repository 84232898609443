import React from 'react'

import { useField } from 'formik'
import MuiTextField from '@mui/material/TextField'

import { useTranslation } from 'react-i18next'

const MemoTextField = React.memo(MuiTextField)

const TextField = ({ name, ...props }) => {
    // console.log('Rendering', name, props)
    const [field, meta] = useField(name)
    const { t } = useTranslation()
    const label = t('label_' + name)
    return (
        <MemoTextField
            name={name}
            id={name}
            value={meta.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            {...props}
            label={label}
        />
    )
}

export default React.memo(TextField)
