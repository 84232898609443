/* global gtag */
import React from 'react'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
const Welcome = () => {
    const { t } = useTranslation()
    const link = `/form${window.location.search}`

    React.useEffect(() => {
        gtag('event', 'form_welcome', {
            event_category: 'Form',
            value: window.location.search,
        })
    }, [])

    return (
        <>
            <h1>{t('welcome_title')}</h1>
            <p>{t('welcome_message')}</p>
            <Button to={link} variant='contained' component={Link} fullWidth>
                {t('welcome_button')}
            </Button>
        </>
    )
}

export default Welcome
