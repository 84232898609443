import React from 'react'

import { useTranslation } from 'react-i18next'

import FileUpload from '../../components/FileUpload'
import Section from '../../components/Section'

const Documents = () => {
    const { t } = useTranslation()
    return (
        <Section title={t('title_documents')}>
            <h3>{t('title_file_cv')}</h3>
            <p>{t('help_cvFile')}</p>
            <FileUpload name='cvFile' />

            <h3>{t('title_file_identity')}</h3>
            <p>{t('help_identityFile')}</p>
            <FileUpload name='identityFile' />

            <h3>{t('title_file_address')}</h3>
            <p>{t('help_addressFile')}</p>
            <FileUpload name='addressFile' />

            <h3>{t('title_file_ni')}</h3>
            <p>{t('help_niFile')}</p>
            <FileUpload name='niFile' />
        </Section>
    )
}

export default Documents
