import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import config from '../../config/config'

import FormControlLabel from '@mui/material/FormControlLabel'
import DescriptionIcon from '@mui/icons-material/Description'
import Checkbox from '@mui/material/Checkbox'
import { useFormikContext, ErrorMessage } from 'formik'

const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>

const TermsLabel = () => {
    return (
        <p>
            I agree to the Terms of Engagement and confirm that the information
            I’ve given on this form is correct.
        </p>
    )
}

const Terms = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values, setFieldValue, handleBlur, setFieldTouched } =
        useFormikContext()

    // Contract url
    const name = `${values.title || '[TITLE]'} ${
        values.forename || '[FORENAME]'
    } ${values.surname || '[SURNAME]'}`

    const payload = {
        name: name,
        name2: name,
        rate: jobData.rate || '[TBC]',
        job: jobData.position || '[TBC]',
        date: new Date().toLocaleDateString(),
    }
    const params = new URLSearchParams()

    Object.keys(payload).forEach((key) => {
        params.set(key, payload[key])
    })

    const contractUrl = `${config.apiBase}/pdf?${params.toString()}`

    return (
        <Section title={t('title_terms')}>
            <p>
                Please review your{' '}
                <a href={contractUrl}>
                    <DescriptionIcon style={{ verticalAlign: 'middle' }} />
                    Terms of Engagement
                </a>
            </p>
            <FormControlLabel
                control={
                    <Checkbox
                        name='terms'
                        checked={values.terms}
                        onChange={(e) => {
                            console.log('TERMS CHANGE', e)
                            setFieldTouched('terms', true)
                            setFieldValue('terms', e.target.checked)
                        }}
                        onBlur={handleBlur}
                    />
                }
                label={<TermsLabel />}
            />
            <ErrorMessage name='terms' component={Error} />
        </Section>
    )
}

export default Terms
