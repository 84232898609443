import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// import TextField from '../../components/TextField'
// import DatePicker from '../../components/DatePicker'

import { useFormikContext, ErrorMessage } from 'formik'
const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>

const DrugAlcohol = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values, setFieldValue, handleBlur, setFieldTouched } =
        useFormikContext()
    return (
        <Section title={t('title_drug_alcohol')}>
            <p>
                As a condition of your Terms of Engagement with Triangle
                Recruitment Ltd, engaged to work at Iron Mountain you are
                required to sign a declaration stating that you do not take any
                drugs (apart from prescribed medication) or any form of
                government banned substances.
            </p>
            <p>
                You will also agree to consent to any random testing for banned
                substances by Iron Mountain while at work on their premises. You
                will also agree not to be under the influence of alcohol whilst
                at work. The above is a requisite of the Health and Safety
                policy.
            </p>
            <FormControlLabel
                control={
                    <Checkbox
                        name='drug_alcohol'
                        onChange={(e) => {
                            setFieldTouched('drug_alcohol', true)
                            setFieldValue('drug_alcohol', e.target.checked)
                        }}
                        onBlur={handleBlur}
                        checked={values.drug_alcohol}
                    />
                }
                label={'I agree to the Compulsory Drug & Alcohol Declaration'}
            />
            <ErrorMessage name='drug_alcohol' component={Error} />
        </Section>
    )
}

export default DrugAlcohol
