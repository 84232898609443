import React from 'react'

const ServerError = ({ message }) => {
    if (!message) {
        return null
    }
    return <p style={{ color: 'red' }}>{message}</p>
}

export default ServerError
