/* global gtag */
import axios from 'axios'
import React from 'react'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'

import { Formik, useFormikContext } from 'formik'

import * as yup from 'yup'

import config from '../config/config'
import { sectionSchemas, sectionFields } from '../config/schema'

import PersonalDetails from './RegistrationForm/PersonalDetails'
import GeneralInformation from './RegistrationForm/GeneralInformation'

import HMRC from './RegistrationForm/HMRC'
import GDPR from './RegistrationForm/GDPR'
import Terms from './RegistrationForm/Terms'
import Errors from '../components/Errors'
import ServerError from '../components/ServerError'
import BankInformation from './RegistrationForm/BankInformation'
import Skills from './RegistrationForm/Skills'
import Documents from './RegistrationForm/Documents'
import Health from './RegistrationForm/Health'
import CriminalRecordDeclaration from './RegistrationForm/CriminalRecordDeclaration'
import Security from './RegistrationForm/Security'
import BackgroundChecks from './RegistrationForm/BackgroundChecks'
import DrugAlcohol from './RegistrationForm/DrugAlcohol'
import DeductionOfWages from './RegistrationForm/DeductionOfWages'
import FinancialBackgroundChecks from './RegistrationForm/FinancialBackgroundChecks'

const { initialValues } = config

const sectionMapping = {
    personalDetails: PersonalDetails,
    generalInformation: GeneralInformation,
    skills: Skills,
    bankInformation: BankInformation,
    documents: Documents,
    health: Health,
    hmrc: HMRC,
    gdpr: GDPR,
    terms: Terms,
    criminalRecordDeclaration: CriminalRecordDeclaration,
    security: Security,
    backgroundChecks: BackgroundChecks,
    drugAlcohol: DrugAlcohol,
    deductionOfWages: DeductionOfWages,
    financialBackgroundChecks: FinancialBackgroundChecks,
}

import sectionConfig from '../config/sections'

const getSections = (name) => {
    return sectionConfig[name].sections
}

const RegistrationForm = ({ jobData, formType, sections }) => {
    const { handleSubmit, isSubmitting, status } = useFormikContext()

    return (
        <form onSubmit={handleSubmit}>
            <Stack gap={1}>
                {/* Dynamic section generation */}
                {sections.map((section, i) => {
                    const Section = sectionMapping[section]
                    return <Section key={i} jobData={jobData} />
                })}

                <Errors />
                <ServerError message={status} />
                <section>
                    <LoadingButton
                        startIcon={isSubmitting}
                        color='primary'
                        variant='contained'
                        fullWidth
                        loading={isSubmitting}
                        type='submit'>
                        Submit
                    </LoadingButton>
                </section>
            </Stack>
        </form>
    )
}

const RegistrationFormWrap = ({ jobData, init }) => {
    console.log('RegistrationFormWrap')

    const formType = jobData.formType || 'ibstock'
    const sections = getSections(formType)
    const validationSchema = yup.object().shape(
        sections.reduce((acc, section) => {
            Object.assign(acc, sectionSchemas[section])
            return acc
        }, {}),
    )

    React.useEffect(() => {
        const data = {
            event_category: 'Form',
            value: window.location.search,
            ...jobData,
            formType,
        }
        gtag('event', 'form_start', data)
    }, [])

    const filteredInitialValues = sections.reduce((acc, section) => {
        sectionFields[section].forEach((key) => {
            acc[key] = initialValues[key]
        })
        return acc
    }, {})

    console.log('filteredInitialValues', filteredInitialValues)

    return (
        <Formik
            validateOnMount={false}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={validationSchema}
            initialValues={Object.assign(
                { ...filteredInitialValues, ...(jobData || {}) },
                Array.from(init.entries()).reduce((acc, [key, value]) => {
                    acc[key] = value
                    return acc
                }, jobData || {}),
            )}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setStatus(null)
                console.log('Submit', values)
                setSubmitting(true)

                // Send analytics event
                gtag('event', 'form_submit', {
                    event_category: 'Form',
                    value: window.location.search,
                    ...jobData,
                    formType,
                })

                axios
                    .post(`${config.apiBase}/submit`, values, {
                        json: true,
                    })
                    .then(() => {
                        setSubmitting(false)
                        window.location = '/success'
                    })
                    .catch((err) => {
                        setSubmitting(false)
                        setStatus('Server Error, please check and try again.')

                        gtag('event', 'form_error', {
                            event_category: 'Form',
                            value: window.location.search,
                            ...jobData,
                            formType,
                            error: err,
                        })
                    })
            }}>
            <RegistrationForm
                jobData={jobData}
                init={init}
                formType={formType}
                sections={sections}
            />
        </Formik>
    )
}

export default RegistrationFormWrap
