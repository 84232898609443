export default {
    ibstock: {
        sections: [
            'personalDetails',
            'generalInformation',
            'skills',
            'bankInformation',
            'documents',
            'health',
            'hmrc',
            'gdpr',
            'security',
            'criminalRecordDeclaration',
            'terms',
        ],
    },
    xaar: {
        sections: [
            'personalDetails',
            'generalInformation',
            'skills',
            'bankInformation',
            'documents',
            'health',
            'hmrc',
            'gdpr',
            'security',
            'criminalRecordDeclaration',
            'terms',
        ],
    },
    triangle: {
        sections: [
            'personalDetails',
            'generalInformation',
            'skills',
            'bankInformation',
            'documents',
            'hmrc',
            'gdpr',
            'security',
            'criminalRecordDeclaration',
            'terms',
        ],
    },
    mountain: {
        sections: [
            'personalDetails',
            'generalInformation',
            'skills',
            'bankInformation',
            'documents',
            'hmrc',
            'financialBackgroundChecks',
            'backgroundChecks',
            'drugAlcohol',
            'deductionOfWages',
            'gdpr',
            'security',
            'criminalRecordDeclaration',
            'terms',
        ],
    },
}
