import React from 'react'

import { useTranslation } from 'react-i18next'

import TextField from '../../components/TextField'
import Select from '../../components/Select'
import Section from '../../components/Section'

import config from '../../config/config'

const { noticePeriods, transports, licenses } = config

const GeneralInformation = ({ jobData = {} }) => {
    const { t } = useTranslation()
    return (
        <Section title={t('title_general_information')}>
            <TextField name='position' disabled={!!jobData.position} />

            <TextField name='site' disabled={!!jobData.site} />

            <Select name='notice_period' items={noticePeriods} required />

            <Select name='transport' items={transports} required />

            <Select name='license' items={licenses} />
        </Section>
    )
}

export default GeneralInformation
