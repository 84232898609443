import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'
import MultiCheckbox from '../../components/MultiCheckbox'

import config from '../../config/config'

const { skills } = config

const Skills = ({ jobData = {} }) => {
    const { t } = useTranslation()
    return (
        <Section title={t('title_skills')}>
            <MultiCheckbox name='skills' items={skills} />
        </Section>
    )
}

export default Skills
