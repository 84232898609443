import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import { useField } from 'formik'

const defaultValues = { no: 'No', yes: 'Yes' }

const MemoRadioGroup = React.memo(RadioGroup)

const RadioField = ({ name, values = defaultValues, ...props }) => {
    const [field, meta, helpers] = useField(name)

    const onChange = (e) => {
        helpers.setTouched(true)
        field.onChange(e)
    }

    const itemContent = React.useMemo(() => {
        return Object.keys(values).map((key) => {
            return (
                <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={values[key]}
                />
            )
        })
    }, [values])

    return (
        <MemoRadioGroup
            row
            name={name}
            value={meta.value}
            onBlur={field.onBlur}
            onChange={onChange}
            {...props}>
            {itemContent}
        </MemoRadioGroup>
    )
}

export default RadioField
