import React from 'react'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useField } from 'formik'
import { fileReader } from '../utils'

const FileUpload = ({
    name,
    // onClose = () => {},
    // onChange = () => {},
    // file = null,
}) => {
    const ref = React.useRef()

    const [field, , helpers] = useField(name)
    const [fieldName, , helpersName] = useField(name + 'Names')

    const handleClose = (i) => {
        ref.current.value = ''
        const newValues = [...field.value]
        newValues.splice(i, 1)
        const newNames = [...fieldName.value]
        newNames.splice(i, 1)
        helpers.setValue(newValues)
        helpersName.setValue(newNames)
        return false
    }

    const update = React.useCallback(
        async (files) => {
            console.log(files)
            const fileDataArray = []
            const fileNames = []
            if (files) {
                await Promise.all(
                    Array.from(files).map(async (file, i) => {
                        const fileData = await fileReader(file)
                        fileDataArray.push(fileData)
                        fileNames.push(file.name)
                        return Promise.resolve()
                    }),
                )
            }
            console.log('FileUpload', fileDataArray, fileNames)
            helpers.setValue(fileDataArray, true)
            helpersName.setValue(fileNames, true)
        },
        [helpers, helpersName],
    )

    const onChange = (event) => {
        update(Array.from(event.target.files))
    }

    return (
        <>
            {field.value
                ? Array.from(field.value).map((file, i) => {
                      return (
                          <Alert
                              key={i}
                              onClose={() => handleClose(i)}
                              sx={{ mb: 1 }}>
                              {fieldName.value[i]}
                          </Alert>
                      )
                  })
                : null}
            <label>
                <input
                    ref={ref}
                    type='file'
                    accept='image/*'
                    multiple
                    onChange={onChange}
                    style={{ display: 'none' }}
                />
                <Button
                    variant='contained'
                    component='span'
                    startIcon={<UploadFileIcon />}>
                    Upload
                </Button>
            </label>
        </>
    )
}

export default FileUpload
