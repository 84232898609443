import React from 'react'

import { useTranslation } from 'react-i18next'

import Section from '../../components/Section'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// import TextField from '../../components/TextField'
// import DatePicker from '../../components/DatePicker'

import { useFormikContext, ErrorMessage } from 'formik'
const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>

const DeductionOfWages = ({ jobData = {} }) => {
    const { t } = useTranslation()
    const { values, setFieldValue, handleBlur, setFieldTouched } =
        useFormikContext()
    return (
        <Section title={t('title_deduction_of_wages')}>
            <p>
                I {values.forename ? values.forename : '[FORENAME]'}{' '}
                {values.surname ? values.surname : '[SURNAME]'} confirm that I
                give my permission for Triangle Recruitment Ltd to deduct the
                necessary amount to obtain a Criminal Records Verification with
                the Disclosure and Barring Service and GBG Group Plc at the
                required level (Standard or Enhanced) for the assignment.
            </p>
            <table className='table'>
                <thead>
                    <tr>
                        <td>Level of Check</td>
                        <td>DBS Fee</td>
                        <td style={{ maxWidth: 100 }}>
                            GBG Group Plc Processing Fee inc VAT
                        </td>
                        <td>Total Fee</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Basic</td>
                        <td>£23.00</td>
                        <td>£12.60</td>
                        <td>£35.60</td>
                    </tr>
                    <tr>
                        <td>Enhanced</td>
                        <td>£40.00</td>
                        <td>£12.60</td>
                        <td>£52.60</td>
                    </tr>
                </tbody>
            </table>
            <p>
                We will deduct the required amount once you have started the
                assignment from your weekly wages. The deducted amount will show
                on your payslip.
            </p>
            <p>
                Once the payment has been collected the DBS Certificate will be
                yours to keep.
            </p>
            <FormControlLabel
                control={
                    <Checkbox
                        name='deduction_of_wages'
                        onChange={(e) => {
                            setFieldTouched('deduction_of_wages', true)
                            setFieldValue(
                                'deduction_of_wages',
                                e.target.checked,
                            )
                        }}
                        onBlur={handleBlur}
                        checked={values.deduction_of_wages}
                    />
                }
                label={
                    'I understand all of the above and give my consent for the deduction of wages'
                }
            />
            <ErrorMessage name='deduction_of_wages' component={Error} />

            <p>
                <i>
                    You can also obtain a Basic DBS Check via&nbsp;
                    <a
                        href='https://www.gov.uk/request-copy-criminal-record'
                        target='_blank'
                        rel='noreferrer'>
                        https://www.gov.uk/request-copy-criminal-record
                    </a>{' '}
                    yourself which will require an identity check online and
                    works best if you have a valid UK passport. The fee for this
                    is £23.00. If you decide to obtain a DBS yourself you will
                    need to pay for this online and provide us with a copy of
                    the certificate.
                </i>
            </p>
        </Section>
    )
}

export default DeductionOfWages
