import React from 'react'
import './App.css'

import axios from 'axios'

import config from './config/config'

import ThemeProvider from '@mui/material/styles/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import enLocale from 'date-fns/locale/en-GB'

import { createTheme } from '@mui/material'

import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'

import themeOptions from './config/theme'

import RegistrationForm from './views/RegistrationFormDynamic'
import Welcome from './views/Welcome'
import Success from './views/Success'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'

function App() {
    const theme = createTheme(themeOptions)
    const queryParams = new URLSearchParams(document.location.search)
    const isMobile = window.document.body.clientWidth < 800

    const { t } = useTranslation()

    const id = queryParams.get('id')
    const [loading, setLoading] = React.useState(!!id)
    const [jobData, setJobData] = React.useState({})

    React.useEffect(() => {
        if (!id) return

        axios
            .get(`${config.apiBase}/rows/${id}`, {
                json: true,
            })
            .then((res) => {
                setJobData(res.data)
                setLoading(false)
                console.log('Got job data', res.data)
            })
    }, [id])

    if (loading) {
        return (
            <p style={{ margin: '100px auto', textAlign: 'center' }}>
                Loading...
            </p>
        )
    }

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={enLocale}>
                    <div className='App'>
                        <Container maxWidth='md' sx={{ p: 0 }}>
                            <Paper
                                sx={{
                                    p: 2,
                                    pl: isMobile ? 1 : 5,
                                    pr: isMobile ? 1 : 5,
                                }}>
                                <Stack
                                    direction='row'
                                    spacing={1}
                                    alignItems='center'
                                    justifyContent='center'
                                    sx={{ mb: 2 }}>
                                    <img
                                        src='/triangle-logo.png'
                                        alt='Triangle Recruitment Logo'
                                    />
                                    <h1>{t('app_title')}</h1>
                                </Stack>
                                <Divider />
                                <Router>
                                    <Switch>
                                        <Route path='/' exact>
                                            <Redirect
                                                to={
                                                    `/welcome` +
                                                    window.location.search
                                                }
                                            />
                                        </Route>
                                        <Route path='/welcome'>
                                            <Welcome />
                                        </Route>
                                        <Route exact path='/form'>
                                            <RegistrationForm
                                                demo={queryParams.get('demo')}
                                                init={queryParams}
                                                jobData={jobData}
                                            />
                                        </Route>
                                        <Route exact path='/success'>
                                            <Success />
                                        </Route>
                                    </Switch>
                                </Router>
                            </Paper>
                        </Container>
                    </div>
                </LocalizationProvider>
            </ThemeProvider>
        </>
    )
}

export default App
