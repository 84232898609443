import React from 'react'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import { useField } from 'formik'

const styles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    padding: 1,
}

const defaultItems = []
const stylesFormControl = { m: 0 }

const MemoFormControl = React.memo(FormControl)
const MemoFormControlLabel = React.memo(FormControlLabel)

const Control = React.memo(function Control({ name, value }) {
    const [field, , helpers] = useField(name)

    const { setValue } = helpers
    const onChange = React.useCallback(
        (e) => {
            const newItemValue = e.target.value
            const newValues = [...field.value]
            if (newValues.includes(newItemValue)) {
                newValues.splice(newValues.indexOf(newItemValue), 1)
            } else {
                newValues.push(newItemValue)
            }
            setValue(newValues, true)
            helpers.setTouched(true)
        },
        [setValue, field.value],
    )

    return (
        <Checkbox
            checked={field.value.includes(value)}
            onChange={onChange}
            name={value}
            value={value}
        />
    )
})

const MultiCheckbox = ({ name, label = false, items = defaultItems }) => {
    const itemsContent = React.useMemo(() => {
        return items.map(({ value, label }, i) => (
            <MemoFormControlLabel
                key={i}
                control={<Control name={name} value={value} />}
                label={label}
            />
        ))
    }, [name, items])

    return (
        <MemoFormControl
            sx={stylesFormControl}
            component='fieldset'
            variant='standard'>
            {label && <FormLabel component='legend'>{label}</FormLabel>}
            <FormGroup sx={styles}>{itemsContent}</FormGroup>
        </MemoFormControl>
    )
}

export default React.memo(MultiCheckbox)
