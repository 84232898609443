import React from 'react'

import { useTranslation } from 'react-i18next'

import TextField from '../../components/TextField'
import DatePicker from '../../components/DatePicker'
import Section from '../../components/Section'

import RadioField from '../../components/RadioField'

import config from '../../config/config'
import { useFormikContext } from 'formik'
const { healthQuestions } = config

const Health = () => {
    const { t } = useTranslation()
    const { values } = useFormikContext()

    const slimValues = Object.keys(values)
        .filter((key) => key.startsWith('health_'))
        .reduce((acc, key) => {
            acc[key] = values[key]
            return acc
        }, {})

    console.log(values, slimValues)

    const healthQuestionsContent = React.useMemo(() => {
        console.log('Render healthQuestionsContent')
        return healthQuestions.map(({ name, title, text }, i) => {
            return (
                <div key={i}>
                    <p>
                        <b>{title}</b>
                        {text ? ' ' + text : null}
                    </p>

                    <RadioField name={name} />

                    {slimValues[name] === 'yes' ? (
                        <TextField
                            name={`${name}_details`}
                            multiline
                            minRows={3}
                        />
                    ) : null}
                </div>
            )
        })
    }, [slimValues])

    return (
        <Section title={t('title_health')}>
            <p>
                Triangle Recruitment Limited provides specialist recruitment and
                outsourcing solutions for the automotive industry. This involves
                placements and engagements at large car production facilities.
                The work required includes the use of heavy machinery and
                complex production tooling. Manual lifting and the handling of
                heavy items is an intrinsic function of such roles. It will not
                be possible for these tasks to be avoided. As a result of this,
                Triangle is required to ask you a series of necessary questions
                about your health, for the purpose of establishing whether you
                are able to perform those functions (with reasonable adjustments
                if you are disabled, if required). Triangle is also aware of its
                duty to make reasonable adjustments for disabled job applicants
                during the recruitment process, and these questions are
                necessary for establishing whether such adjustments are
                required.
            </p>
            <p>
                Triangle is fully aware of its obligations under the{' '}
                <a
                    href='https://www.gov.uk/guidance/equality-act-2010-guidance'
                    target='_blank'
                    rel='noreferrer'>
                    Equality Act 2010
                </a>{' '}
                in respect of the request for this information, and acts fully
                in compliance with the provisions of that legislation.
            </p>
            <p>
                Please complete the following questions to the best of your
                knowledge:
            </p>

            <DatePicker name='date_last_medical' />

            <p>
                Give details of any current illness or recent accident, the
                effects of which you may still be recovering from. Please
                include details of any periods of hospitalisation:
            </p>
            <TextField name='health_details' minRows={3} multiline />

            <h3>Current Health Status</h3>
            <p>
                <b>
                    As a result of the inherent physical nature of the work to
                    be performed, it is possible that a wide range of health
                    conditions may affect your ability to perform such work.
                    Triangle is therefore required (including for health and
                    safety reasons) to ask specific questions relating to
                    specific health conditions.
                </b>
            </p>
            <h4>Do you currently suffer from:</h4>
            {healthQuestionsContent}
        </Section>
    )
}

export default Health
