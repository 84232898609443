import React from 'react'
import MuiDatePicker from '@mui/lab/DatePicker'

import TextField from './TextField'

import { useField } from 'formik'

const MemoDatePicker = React.memo(MuiDatePicker)

const renderInput = (name) =>
    function Input(params) {
        return <TextField name={name} {...params} />
    }

const DatePicker = ({
    name,
    maxDate = new Date(),
    required = false,
    ...props
}) => {
    const [field, meta, helpers] = useField(name)
    const renderInputMemo = React.useMemo(() => renderInput(name), [name])

    const onChange = React.useCallback(
        (newValue) => {
            console.log('On change', newValue)
            helpers.setValue(newValue)
        },
        [helpers],
    )

    const onBlur = React.useCallback(() => {
        field.onBlur()
    }, [field])

    return (
        <MemoDatePicker
            id={name}
            name={name}
            value={meta.value}
            // maxDate={maxDate}
            required={required}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            onChange={onChange}
            onBlur={onBlur}
            renderInput={renderInputMemo}
            {...props}
        />
    )
}

export default DatePicker
